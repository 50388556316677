import DashboardIcon from '@mui/icons-material/Dashboard'
import DescriptionIcon from '@mui/icons-material/Description'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { useNavigate, matchPath } from 'react-router-dom'

// eslint-disable-next-line import/order
import Logo from '@src/common/components/Logo'

interface DrawerListProps {
  open: boolean
  handleDrawerClose?: () => void
  showHeader?: boolean
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 0,
  ...theme.mixins.toolbar,
  maxHeight: '80px',
}))

import useSearchResults from '@src/features/SearchResults/useSearchResults'

import RecursiveMenu from './MenuuCategory'

const SEARCH_RESULTS_PATH = '/dashboard'

const DrawerList: React.FC<DrawerListProps> = ({ open }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const xxlScreen = useMediaQuery(theme.breakpoints.down('xxl'))

  const { lenghtPerCategories, selectedCategoryKey, handleCategoryChange } =
    useSearchResults()

  const handleNavigation = (path: string) => {
    navigate(path)
  }

  const menuItems = []

  const isSearchResultsPage =
    matchPath({ path: SEARCH_RESULTS_PATH, end: false }, location.pathname) !==
    null

  return (
    <>
      <DrawerHeader>
        <Box>
          <Logo isSideBar={true} />
        </Box>
      </DrawerHeader>
      <Divider />

      <List>
        {[
          {
            // path: '/dashboard',
            text: 'Dashboard',
            Icon: DashboardIcon,
          },
          { path: '/', text: 'Search', Icon: FindInPageIcon },
          {
            // path: '/search-results',
            text: 'Search results',
            Icon: DescriptionIcon,
          },
        ].map(({ path, text, Icon }, index) => (
          <ListItem
            key={`${index}-${path}`}
            onClick={() => path && handleNavigation(path)}
            disablePadding
            sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                color: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  color: theme.palette.text.sidebarText,
                },
                '&:hover .MuiListItemIcon-root': {
                  color: theme.palette.text.sidebarText,
                },
                '&:hover .MuiListItemText-primary': {
                  color: theme.palette.text.sidebarText,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'inherit',
                }}
              >
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{
                  opacity: open ? 1 : 0,
                  color: 'inherit',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {(isMobile || xxlScreen) && menuItems?.length ? (
        <Divider sx={{ borderColor: 'white' }} />
      ) : null}
      {isSearchResultsPage && (isMobile || xxlScreen) && (
        <Box
          sx={{
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.grey[400],
              borderRadius: '1px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: theme.palette.grey[600],
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <List>
            <RecursiveMenu
              lenghtPerCategories={lenghtPerCategories}
              selectedKey={selectedCategoryKey}
              setSelectedKey={handleCategoryChange}
              defaultOpenKey="category"
            />
          </List>
        </Box>
      )}
    </>
  )
}

export default DrawerList
