import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import React from 'react'

import AppProviders from '@src/AppProviders'
import { useLayoutContext } from '@src/common/hooks/useLayoutContext'
import Router from '@src/routes/Router'
import { createTheme } from '@src/theme'

const AppInner: React.FC = () => {
  const { settings } = useLayoutContext()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(settings.theme)}>
        <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const App: React.FC = () => (
  <AppProviders>
    <AppInner />
  </AppProviders>
)

export default App
