// src/api/reportsApi.ts

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { ReportRequest } from '@src/features/SearchResults/types'
import { prepareAuthHeaders } from '@src/utils/helpers'

const apiBaseUrl = import.meta.env.VITE_API_URL

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    generateReport: builder.mutation<Blob, ReportRequest>({
      query: (body) => ({
        url: '/report/v2',
        method: 'POST',
        body,
        responseHandler: async (response) => response.blob(),
      }),
    }),
  }),
})

export const { useGenerateReportMutation } = reportsApi
