import {
  useMediaQuery,
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
} from '@mui/material'
import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import {
  styled,
  useTheme,
  type Theme,
  type CSSObject,
} from '@mui/material/styles'
import * as React from 'react'
import { lazy, type ReactNode } from 'react'
import { useState } from 'react'

import DrawerList from './DrawerList'
import MobileDrawer from './DrowerMobile'

const drawerWidth = 240

const Topbar = lazy(async () => import('@src/layouts/topbar'))

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#fff',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  //   backgroundColor: theme.palette.primary.main,
}))

interface DrawerProps {
  open: boolean
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      backgroundColor: theme.palette.background.sidebar,
      color: theme.palette.text.primary,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      backgroundColor: theme.palette.background.sidebar,
      color: theme.palette.text.primary,
    },
  }),
}))

interface MiniDrawerProps {
  children: ReactNode
}

export default function MiniDrawer({ children }: MiniDrawerProps) {
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {isMobile ? (
        <>
          <Topbar open={open} openSidebar={handleDrawerOpen} />
          <MobileDrawer open={open} setOpen={setOpen}>
            <DrawerList open={open} />
          </MobileDrawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: theme.palette.background.default,
              marginBottom: '56px',
            }}
          >
            {children}
          </Box>
        </>
      ) : (
        <>
          <AppBar position="fixed" open={open}>
            <Topbar
              open={open}
              openSidebar={handleDrawerOpen}
              showHeader={isMobile}
            />
          </AppBar>
          <Drawer variant="permanent" role="presentation" open={open}>
            <DrawerList open={open} />
          </Drawer>
          <Box
            component="div"
            sx={{
              marginLeft: open ? `${drawerWidth}px` : '66px',
              transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              marginTop: '70px',
              backgroundColor: theme.palette.background.default,
            }}
          >
            {children}
          </Box>
        </>
      )}
    </Box>
  )
}
